<template>

    <div class="bigBox">
        <div class="dlTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toBack">
            <p class="dlTopBoxTitle">登录账户</p>
        </div>

        <div class="dlBox1">
            <p class="dlBox1P1">您好,</p>
            <p class="dlBox1P2">
                欢迎加入
                <!-- <span v-if="dltype == 1" style="color:#A30001;" @click="dltype = 2,yzmValue = ''">注册</span>
                <span v-else-if="dltype == 2" style="color:#A30001;" @click="dltype = 1,yzmValue = ''">登录</span> -->

            </p>
            <div class="phoneBox">
                <input type="txt" class="phoneIpt" v-model="phoneValue" placeholder="请输入手机号">
            </div>
            <div class="yzmBox">
                <input type="txt" class="yzmipt" v-model="yzmValue" placeholder="请输入验证码">
                <div class="yzmBtn1" v-if="yzmLoad" @click="getYzm">获取验证码</div>
                <div class="yzmBtn2" v-else-if="!yzmLoad">{{yzmLoadTime}}s</div>
            </div>
            <div class="dlbtn1" v-if="phoneValue != ''&&yzmValue != ''" @click="toPhoneRegister">登录</div>
            <div class="dlbtn2" v-else>登录</div>



        </div>
    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            dltype:1,
            phoneValue:'',
            yzmValue:'',
            yzmLoad:true,
            yzmLoadTime:30,
            yzmInterval:null,
            load : null,
            wjsToken: null,
            wjsUserInfo: null,
        }
    },
    methods:{
        toBack(){
            this.$router.back(-1)
        },
        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        getYzm(){
            if (this.phoneValue == "" || this.phoneValue.replace(" ", "") == "") {
                this.showErr("请填写您的手机号")

                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phoneValue))) {
                this.showErr("请输入有效的手机号码")

                return;
            }
            this.showLoading()
            this.yzmLoad = false;
            this.yzmLoadTime = 30;
            this.yzmInterval = setInterval(() => {
                if(this.yzmLoadTime > 1){
                    this.yzmLoadTime--;
                }else{
                    this.yzmLoad = true;
                    this.yzmLoadTime = 30;
                    clearInterval(this.yzmInterval)
                }
            }, 1000);

            sms({
                "mobile":this.phoneValue
            }).then(res => {
                this.load && this.load.close();
                if(res.success){
                    this.showSuccess("验证码已发送")
                }else{
                    this.showErr(res.message)
                }
                
            })


        },
        toPhoneRegister(){
            if (this.phoneValue == "" || this.phoneValue.replace(" ", "") == "") {
                this.showErr("请填写您的手机号")
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phoneValue))) {
                this.showErr("请输入有效的手机号码")
                return;
            }
            if (this.yzmValue == "" || this.yzmValue.replace(" ", "") == "") {
                this.showErr("请填写验证码")

                return;
            }
            this.showLoading()

            phoneRegister({
                "mobile":this.phoneValue,
                "captcha":this.yzmValue
            }).then(res => {
                this.load && this.load.close();
                if(res.success){
                    this.showSuccess(res.message)
                    Cookies.set('wjsToken', res.result.token, { expires: 30 })
                    Cookies.set('wjsUserInfo', res.result.userInfo, { expires: 30 })
                    this.$router.back(-1)

                }else{
                    this.showErr(res.message)
                }

            })

        },

    },
    mounted(){
        this.load && this.load.close();

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        if(this.wjsToken&&this.wjsUserInfo){
            
            this.toLink('grzx');
        }


    }

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;
    .dlTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .dlTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }
    .dlBox1{
        padding: .86rem 1rem;
        box-sizing: border-box;
        width: 100%;
        .dlBox1P1{
            font-size: .7rem;
            color: #343434;
            margin-bottom: .14rem;
        }
        .dlBox1P2{
            font-size: .34rem;
            color: #A5A5A5;
            margin-bottom: 1rem;
        }
        .phoneBox{
            width: 100%;
            border-bottom: .02rem solid rgba(219, 219, 219, .4);
            .phoneIpt{
                border: 0;
                outline: none;
                font-size: .3rem;
                color: #343434;
            }
        }
        .yzmBox{
            width: 100%;
            border-bottom: .02rem solid rgba(219, 219, 219, .4);
            position: relative;
            margin-bottom: 1rem;
            .yzmipt{
                border: 0;
                outline: none;
                font-size: .3rem;
                color: #343434;
            }
            .yzmBtn1{
                position: absolute;
                right: 0;
                bottom: 0.1rem;
                border-radius: 0.3rem;
                background: #A30001;
                padding: .08rem .3rem;
                font-size: .32rem;
                color: #FFFFFF;
                display: inline-table;
            }
            .yzmBtn2{
                position: absolute;
                right: 0;
                bottom: 0.1rem;
                border-radius: 0.3rem;
                background: #DFDCDD;
                padding: .08rem .5rem;
                font-size: .32rem;
                color: #FFFFFF;
                display: inline-table;

            }
        }
        .dlbtn1{
            float: right;
            border-radius: 2rem;
            background: #A30001;
            padding: .2rem .8rem;
            font-size: .32rem;
            color: #FFFFFF;
            display: inline-table;
        }
        .dlbtn2{
            float: right;
            border-radius: 2rem;
            background: #DFDCDD;
            padding: .2rem .8rem;
            font-size: .32rem;
            color: #FFFFFF;
            display: inline-table;
        }
    }
}




</style>